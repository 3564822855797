import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function AddanAmazonOpenSearchServerlessVectorStore(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - Add Amazon OpenSearch Serverless</title>
        <meta
          name="description"
          content="How to add an Amazon OpenSearch Serverless Vector Store"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/add-an-amazon-opensearch-serverless-vector-store/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>
                Add an Amazon OpenSearch Serverless Vector Store
              </h2>
            </div>
            <div>
              <p>
                In this guide, we will walk you through the process of
                integrating an AWS-hosted Amazon OpenSearch Serverless instance
                as an asset in Dataster. This includes configuring the necessary
                IAM permissions, creating a vector index, and adding the vector
                store to the Dataster Vector Store catalog. Specifically, we
                will index a set of movies along with their titles, years,
                summaries, and vectorized representations of the summaries. By
                the end of this guide, you will have a fully functional vector
                store in Dataster that can be used to create a
                Retrieval-Augmented Generation (RAG) system.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Vector search collection has been created in AWS.</li>
                  <li>The collection is publicly accessible.</li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Configure IAM Principal
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Ensure the IAM principal has an access key and a secret
                    access key.
                  </li>
                  <li>
                    Assign the necessary privileges in IAM and Amazon OpenSearch
                    Serverless to read documents in the collection.
                  </li>
                  <li>
                    Add the additional necessary privileges in IAM and Amazon
                    OpenSearch if the index need to be created.
                  </li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/ossIAMRole.png"
                alt="IAM permissions"
                title="IAM permissions"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>
                Step 2: Create a Vector Index
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Create a vector index within the collection.</li>
                  <li>
                    Name the index "movies" and the vector field "vector".
                  </li>
                  <li>
                    Index movies with their titles, years, summaries, and a
                    vectorized representation of their summaries.
                  </li>
                  <li>
                    Use the nmslib engine and set the dimensions to 1,024, as we
                    will use the Amazon Titan Text Embedding V2 model. Note that
                    dimensions may vary with different embedding models.
                  </li>
                  <li>
                    Choose cosine distance for the vector similarity measure.
                  </li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/ossAddVectorField.png"
                alt="add a vector field"
                title="Vector Field"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 3: Ingest Data</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Ingest the following movies and their vectorized summaries:
                  </li>
                  <code>
                    {JSON.stringify(
                      [
                        {
                          title: "The Shawshank Redemption",
                          year: 1994,
                          summary:
                            "Two imprisoned men bond over a number of years, finding solace and eventual redemption through acts of common decency.",
                        },
                        {
                          title: "The Godfather",
                          year: 1972,
                          summary:
                            "The aging patriarch of an organized crime dynasty transfers control of his clandestine empire to his reluctant son.",
                        },
                        {
                          title: "The Dark Knight",
                          year: 2008,
                          summary:
                            "When the menace known as the Joker emerges from his mysterious past, he wreaks havoc and chaos on the people of Gotham.",
                        },
                        {
                          title: "Pulp Fiction",
                          year: 1994,
                          summary:
                            "The lives of two mob hitmen, a boxer, a gangster and his wife, and a pair of diner bandits intertwine in four tales of violence and redemption.",
                        },
                        {
                          title: "Inception",
                          year: 2010,
                          summary:
                            "A thief who steals corporate secrets through the use of dream-sharing technology is given the inverse task of planting an idea into the mind of a CEO.",
                        },
                      ],
                      null,
                      2
                    )}
                  </code>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>Step 4: Add Vector Store</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Navigate to the Dataster Vector Store catalog.</li>
                  <li>Add the Amazon OpenSearch Serverless Vector store.</li>
                  <li>
                    Specify the embedding model used to create the vectors.
                  </li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/ossAddStore.png"
                alt="Add an Amazon OpenSearch Serverless vector store"
                title="Add an Amazon OpenSearch Serverless vector store"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 5: Explore Chunks</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Use the explorer to examine the chunks.</li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/ossChunkExplorer.png"
                alt="Chunk Explorer"
                title="Chunk Explorer"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully set up Amazon OpenSearch Serverless as a
                Vector Store in Dataster. This setup is a preliminary step that
                will enable you to create a Retrieval-Augmented Generation (RAG)
                system by combining this Vector Store with the Large Language
                Model (LLM) of your choice and a system prompt.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
