import React from "react";
import { NavLink } from "react-router-dom";

export default function SideMenu() {
  return (
    <div className="side-menu">
      <ul>
        <li>
          <NavLink
            to="/"
            end
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Home
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/solutions/"
            end
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Solutions
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/pricing/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Pricing
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/news/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            News
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/docs/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Docs
          </NavLink>
        </li>

        {/* <li>
          <NavLink
            to="/privacy"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Privacy
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink
            to="/terms/"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Terms
          </NavLink>
        </li> */}
        <div className="signup">
          <a href="https://app.dataster.com/signup">Get Started</a>
        </div>
      </ul>
    </div>
  );
}
