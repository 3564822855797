import styles from "./newsMonth.module.css";

export default function NewsMonth({ month, children }) {
  return (
    <div className={styles.container}>
      <span className={styles.month}>{month}</span>
      {children}
    </div>
  );
}
