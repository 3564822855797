import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function LLMsOverview(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - LLM Overview</title>
        <meta
          name="description"
          content="Explore Dataster's Large Language Model Catalog"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/llms-overview/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>LLMs Overview</h2>
            </div>
            <div>
              <p>
                When building a GenAI application, developers often need to test
                multiple models to determine which one performs best for their
                specific use case. To facilitate this, Dataster supports over 15
                curated, high-quality Large Language Models (LLMs) from various
                providers across several cloud platforms. The full list of
                models can be found on our pricing page{" "}
                <NavLink
                  to="/pricing"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  here
                </NavLink>
                .
              </p>
              <br />
              <p>
                Models in Dataster fall into two main categories: completion and
                embedding. For convenience, Dataster provides out-of-the-box
                access to each of the supported models. These models are
                automatically populated in the LLM Catalog upon account
                creation. A filter allows you to view only the LLMs from a
                specific cloud provider or those with a specific pattern in the
                name, such as "oai".
              </p>

              <br />
              <ImageGallery
                image="/documentation/llmCatalog.png"
                alt="LLM catalog"
                title="LLM management"
              />
              <br />

              <p>
                The models that Dataster provides access to out-of-the-box are
                shared. For private access, users can bring their own models
                into Dataster as assets, ensuring that only they can access
                those models. The catalog displays Bring Your Own (BYO) LLMs
                differently from the Dataster-provided LLMs. Instead of showing
                the Dataster badge, it gives the user the option to edit or
                delete the model from the catalog.
              </p>

              <br />
              <ImageGallery
                image="/documentation/vsBYOLlm.png"
                alt="Dataster provided vs BYO LLM"
                title="Dataster provided vs BYO LLM"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                Dataster supports over 15 Large Language Models for completions
                or embeddings. Shared access to each model is provided
                out-of-the-box to every user. Users are also welcome to bring
                their own models into the LLM Catalog for private access,
                isolated from other Dataster users. Models added to the catalog
                can later be used for use case-specific evaluation.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
