import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function AddaRAG(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - Add a RAG</title>
        <meta name="description" content="How to add a RAG" />
        <link rel="canonical" href="https://www.dataster.com/docs/add-a-rag/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>Add a RAG</h2>
            </div>
            <div>
              <p>
                In this guide, we will walk you through the process of adding a
                Retrieval-Augmented Generation (RAG) as an asset in Dataster.
                This includes specifying the language models to use for
                embedding and completion, specifying the vector store for
                retrieval, and setting the Top N parameter. Specifically, we
                will create a RAG that uses text-embedding-3-small for
                embedding, GPT-4o for completion, and Azure AI Search for
                document retrieval.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A GPT-4o model in the LLM catalog.</li>
                  <li>A text-embedding-3-small model in the LLM catalog.</li>
                  <li>A vector index in the vector store catalog.</li>
                  <li>
                    The embeddings in the vector index have previously been
                    created with text-embedding-3-small.
                  </li>
                </ol>
              </p>

              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the RAG Catalog
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Navigate to the RAG catalog.</li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/addaRag.png"
                alt="LLM catalog"
                title="LLM management"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 2: Add a RAG</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Click add a RAG.</li>
                  <li>Fill in a unique name for the RAG across the account.</li>
                  <li>
                    Specify an existing Completion LLM. In our example with
                    GPT-4o, you can bring your own or use one the off-the-shelf
                    Dataster models.
                  </li>
                  <li>
                    Specify an existing Vector Store. In our example, we have an
                    Azure AI Search index called "Demo".
                  </li>
                  <li>
                    Specify an existing Embedding LLM for inference. In our
                    example with text-embedding-3-small you can bring your own
                    or use one the off-the-shelf Dataster models.
                  </li>
                  <li>
                    The user interface shows a warning if the embedding model
                    for inference does not match the model used to create the
                    embeddings in the first place.
                  </li>
                  <li>
                    Set the Top N parameter. This parameter dictates how many
                    chunks of text are to be fetched from the vector store.
                  </li>
                  <li>Click Save.</li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/rags.png"
                alt="Chunk Explorer"
                title="Chunk Explorer"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 3: View/Use the RAG</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Return to the RAG Catalog.</li>
                  <li>The RAG appears in the order it was created.</li>
                </ol>
                <br />
              </p>
              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully created a RAG as an asset in Dataster.
                This RAG is now ready for latency and accuracy evaluation at the
                scale of an entire use case.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
