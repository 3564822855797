import {
  TwitterShareButton,
  XIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

const ShareButtons = ({ url, title }) => {
  return (
    <div style={{ display: "flex", gap: "0.25rem" }}>
      <EmailShareButton url={url} subject={title}>
        <EmailIcon size={24} round />
      </EmailShareButton>
      <LinkedinShareButton url={url} title={title}>
        <LinkedinIcon size={24} round />
      </LinkedinShareButton>
      <TwitterShareButton url={url} title={title}>
        <XIcon size={24} round />
      </TwitterShareButton>
    </div>
  );
};

export default ShareButtons;
