import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function RAGOverview(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - RAG Overview</title>
        <meta
          name="description"
          content="Explore Dataster's Retrieval Augmented Generation capabilities"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/rag-overview/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Robot" }}>RAG Overview</h2>
            </div>
            <div>
              <p>
                Most GenAI applications require grounding data in some way,
                shape, or form to produce accurate answers. For that reason,
                Retrieval-Augmented Generation (RAG) is a widely adopted
                architecture for many use cases. When building a RAG, developers
                have many components to choose from and important decisions to
                make, such as selecting the right Large Language Model (LLM),
                the appropriate vector store, and the best set of parameters.
                The number of combinations can sometimes be overwhelming.
                Dataster helps determine the best components for a specific use
                case. We support many Large Language Models, several vector
                stores, and allow users to set the Top N parameter, which
                dictates how many text chunks are to be fetched from the vector
                store and included in the prompt. The full list of supported
                models can be found on our pricing page.{" "}
                <NavLink
                  to="/pricing"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  here
                </NavLink>
                .
              </p>

              <br />
              <ImageGallery
                image="/documentation/ragOverview.png"
                alt="RAG Overview"
                title="RAG Overview"
              />
              <br />

              <p>
                Our approach lets users quickly create multiple RAGs and test
                which ones perform best for their specific use case in terms of
                both latency and accuracy of the answers. For example, if a user
                has already determined that the best model for their use case is
                GPT-4o and the best vector store is an index they have in Azure
                AI Search, they can quickly create multiple instances of this
                RAG with different values for the Top N parameter. This allows
                them to determine the optimal number of text chunks to fetch for
                their specific use case with their specific data.
              </p>

              <br />
              <ImageGallery
                image="/documentation/twoRAGs.png"
                alt="Two RAGs with different TOP N values"
                title="Two RAGs with different TOP N values"
              />
              <br />

              <p>
                Another interesting pattern that Dataster allows users to
                implement is multi-cloud RAGs, where the grounding data resides
                in one cloud and the Large Language Models in another. For
                example, a user may have their data in an Azure AI Search index
                but wish to use Claude 3 Sonnet on Amazon Bedrock. Another user
                may have their data in Amazon OpenSearch Serverless but wish to
                use GPT-4o for completion.
              </p>

              <br />
              <ImageGallery
                image="/documentation/mProviderRag.png"
                alt="Multiprovider RAG"
                title="Multiprovider RAG"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                Dataster allows users to easily create RAG architectures by
                assembling the necessary components such as vector store,
                embedding model, completion model, and specifying the Top N
                parameter. Such a RAG can then be tested manually or
                automatically for a specific use case.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
