import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function AddaPrompt(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - Add a Prompt</title>
        <meta
          name="description"
          content="First step towards Prompt engineering"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/add-a-prompt/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>Add a Prompt</h2>
            </div>
            <div>
              <p>
                Prompts are central to Dataster, allowing users to test their
                applications against specific use cases rather than relying on
                benchmarks with generic data. In this guide, we will walk you
                through the process of adding a prompt to your catalog and
                optionally specifying a ground truth for automated evaluation
                purposes.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Dataster account</li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the Prompt Catalog
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Navigate to the prompt catalog. The prompt catalog is also
                    the landing page after signing in.
                  </li>
                  <li>
                    Dataster bootstraps new accounts with an initial user prompt
                    and a system prompt.
                  </li>
                </ol>
              </p>
              <br />

              <ImageGallery
                image="/documentation/promptCatalog.png"
                alt="Prompt catalog"
                title="Prompt management"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 2: Add a Prompt</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Click add Prompt.</li>
                  <li>
                    Fill in a unique name for the prompt across the account.
                  </li>
                  <li>
                    Specify an existing use case for the prompt or create a new
                    one.
                  </li>
                  <li>
                    Select the type of prompt: "system", "user", or "assistant".
                    This setting impacts how the prompt can be used in the
                    application.
                  </li>
                  <li>
                    For the "user" type, if the prompt is a question, optionally
                    set the ground truth answer. This will make the prompt
                    available for automated model and RAG evaluations.
                  </li>
                  <li>Click Save.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/addPrompt.png"
                alt="Add User Prompt"
                title="Add User Prompt"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Step 3: View/Use the Prompt</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Return to the Prompt Catalog.</li>
                  <li>The prompt appears in the order it was created.</li>
                  <li>Optionally reorder or filter the prompts.</li>
                  <li>Send the prompt to the playground for testing.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/newPrompt.png"
                alt="Add User Prompt"
                title="Add User Prompt"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully created a new prompt in Dataster. This is
                the first step in creating a full use case that you can
                eventually test against a broad variety of language models and
                Retrieval-Augmented Generation (RAG) systems.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
