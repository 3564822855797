import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function BulkDeletePrompts(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - Bulk delete Prompts</title>
        <meta
          name="description"
          content="Learn how to delete prompts from the catalog in bulk"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/bulk-add-prompts/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>Bulk Delete Prompts</h2>
            </div>
            <div>
              <p>
                Prompts are central to Dataster, allowing users to test their
                applications against specific use cases rather than relying on
                benchmarks with generic data. There is no cost associated with
                keeping prompts in the catalog. However, users may want to
                delete their prompts once they have finished running their
                evaluations. Although it is possible to manually delete prompts
                one by one, this can become tedious for large use cases with
                tens, hundreds, or thousands of prompts. In this guide, we will
                walk you through the process of deleting prompts from your
                catalog in bulk.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Dataster account.</li>
                  <li>Prompts in the catalog.</li>
                  <li>
                    A json lines (JSONL) file with the names of the prompts to
                    delete.
                  </li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the Catalog
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Navigate to the prompt catalog. The prompt catalog is also
                    the landing page after signing in.
                  </li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/promptCatalog2.png"
                alt="Prompt catalog"
                title="Prompt management"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 2: Delete Prompts</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Click Bulk Delete.</li>
                  <li>
                    Use the file picker to select a JSON Line (JSONL) file from
                    your local computer.
                  </li>
                  <li>
                    Ensure the JSONL file contains the required key: "name". Any
                    other keys will be ignored. If the prompts to be deleted
                    were initially bulk added with a JSONL file, the same file
                    can be used.
                  </li>
                  <li>Click Delete.</li>
                </ol>
                <br />
                Example JSONL file content:
                <pre>
                  <code>
                    {JSON.stringify({ name: "Trades" }, null, 0)}
                    <br />
                    {JSON.stringify({ name: "Record" }, null, 0)}
                  </code>
                </pre>
              </p>

              <h3 style={{ margin: "1rem 0" }}>
                Step 3: Check the Deletion Status
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    In case of an error, the upload process moves on to the next
                    line in the file.
                  </li>
                  <li>
                    At the end of the deletion process, the number of successes
                    and failures are displayed.
                  </li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/bulkDeletePrompts.png"
                alt="Delete User Prompts"
                title="Delete User Prompt"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>
                Step 4: View the Updated Catalog
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Return to the Prompt Catalog.</li>
                  <li>The prompts no longer appear.</li>
                  <li>
                    Prompts that were not included in the JSONL file are still
                    present.
                  </li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/emptyCatalog.png"
                alt="New Prompts"
                title="New Prompts"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully bulk deleted prompts in Dataster. This
                allows users to keep a lean catalog once they are done with
                their testing.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
