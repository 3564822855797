import React from "react";

export default function ChevronRightIcon(props) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_622_16)">
        <path
          d="M237.093 214.613L278.48 256L237.093 297.387C236.106 298.374 235.323 299.547 234.788 300.837C234.254 302.127 233.979 303.51 233.979 304.907C233.979 306.303 234.254 307.686 234.788 308.976C235.323 310.267 236.106 311.439 237.093 312.427C238.081 313.414 239.253 314.198 240.544 314.732C241.834 315.266 243.217 315.542 244.613 315.542C246.01 315.542 247.393 315.266 248.683 314.732C249.973 314.198 251.146 313.414 252.133 312.427L301.093 263.467C305.253 259.307 305.253 252.587 301.093 248.427L252.133 199.467C251.147 198.478 249.974 197.693 248.684 197.158C247.394 196.623 246.01 196.347 244.613 196.347C243.216 196.347 241.833 196.623 240.543 197.158C239.252 197.693 238.08 198.478 237.093 199.467C233.04 203.627 232.933 210.453 237.093 214.613Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_622_16">
          <rect
            width="256"
            height="256"
            fill="currentColor"
            transform="translate(128 384) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
