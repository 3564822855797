import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function LatencyOverview(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - LLM Overview</title>
        <meta
          name="description"
          content="Discover Dataster RAG and LLM Latency testing capabilities"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/latency-overview/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>Latency Tests Overview</h2>
            </div>
            <div>
              <p>
                GenAI applications fall under multiple categories, including
                real-time, best effort, and batch-oriented. The most prevalent
                type of application has been the real-time type. Organizations
                of all sizes have been building assistants, copilots, or
                question-and-answer chatbots. For these types of applications,
                it is crucial to deliver an output back to the user in a timely
                fashion. Latency can significantly impact the success or failure
                of a GenAI real-time application. To assess the expected latency
                of their application, builders often rely on public benchmarks
                for a given set of models. However, the value of these latency
                benchmarks is limited for several reasons. First, the input
                prompts used in the benchmarks differ significantly from the
                actual prompts used for a specific enterprise use case. Second,
                if the builder adds Retrieval-Augmented Generation (RAG) to the
                equation and their arbitrary vector store, the benchmarks become
                even less relevant.
              </p>
              <br />
              <p>
                To tackle this problem, Dataster provides a robust latency test
                framework that allows builders to test their use case at scale
                by sending hundreds of user prompts to various combinations of
                system prompts, Large Language Models (LLMs), and optionally
                vector stores for RAG. This way, the builder can create as many
                RAG combinations as necessary to determine which one will
                deliver the best latency for their use case and their users.
              </p>
              <br />
              <ImageGallery
                image="/documentation/latencyJobInputs.png"
                alt="LLM catalog"
                title="LLM management"
              />
              <br />
              <p>
                This capability works by sending requests to the selected RAGs
                and LLMs in parallel and individually measuring the time it
                takes to receive the full output.
              </p>
              <br />
              <ImageGallery
                image="/documentation/latencyJobRun.png"
                alt="LLM catalog"
                title="LLM management"
              />
              <br />
              <p>
                When all the results are received, Dataster compiles statistics
                for each model, each RAG, and each system prompt, presenting the
                average latency and standard deviation.
              </p>
              <br />
              <ImageGallery
                image="/documentation/latencyJobResult.png"
                alt="LLM catalog"
                title="LLM management"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                Dataster's latency test framework empowers builders to
                accurately assess the performance of their GenAI applications in
                real-world scenarios. By allowing extensive testing across
                various RAG combinations and LLMs, Dataster ensures that
                developers can identify the optimal setup for their specific use
                case. This comprehensive approach helps deliver timely and
                reliable outputs, ultimately enhancing the user experience and
                the overall success of real-time GenAI applications.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
