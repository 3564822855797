import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function BulkAddPrompts(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation - Bulk add Prompts</title>
        <meta
          name="description"
          content="Learn how to quickly build your prompt catalog"
        />
        <link
          rel="canonical"
          href="https://www.dataster.com/docs/bulk-add-prompts/"
        />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>Bulk Add Prompts</h2>
            </div>
            <div>
              <p>
                Prompts are central to Dataster, allowing users to test their
                applications against specific use cases rather than relying on
                benchmarks with generic data. In this guide, we will walk you
                through the process of adding prompts to your catalog in bulk
                and optionally specifying a ground truth for automated
                evaluation purposes.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Dataster account</li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the Catalog
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Navigate to the prompt catalog. The prompt catalog is also
                    the landing page after signing in.
                  </li>
                  <li>
                    Dataster bootstraps new accounts with an initial user prompt
                    and a system prompt.
                  </li>
                </ol>
              </p>
              <br />
              <ImageGallery
                image="/documentation/promptCatalog.png"
                alt="Prompt catalog"
                title="Prompt management"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Step 2: Add Prompts</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Click Bulk Add.</li>
                  <li>
                    Use the file picker to select a JSON Line (JSONL) file from
                    your local computer.
                  </li>
                  <li>
                    Ensure the JSONL file contains the required keys: "name",
                    "useCase", "category", and "content". The "groundTruth" key
                    is optional. Any other keys will be ignored.
                  </li>
                  <li>Click Add.</li>
                </ol>
                <br />
                Example JSONL file content:
                <pre>
                  <code>
                    {JSON.stringify(
                      {
                        name: "Magic",
                        useCase: "nba",
                        category: "user",
                        content: "Who is Magic Johnson?",
                      },
                      null,
                      0
                    )}
                    <br />
                    {JSON.stringify(
                      {
                        name: "MVP#1",
                        useCase: "nba",
                        category: "user",
                        content: "Who was the first MVP?",
                      },
                      null,
                      0
                    )}
                  </code>
                </pre>
              </p>

              <h3 style={{ margin: "1rem 0" }}>
                Step 3: Check the upload status
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    In case of an error, the upload process moves on to the next
                    line in the file.
                  </li>
                  <li>
                    At the end of the upload process, the number of successes
                    and failures are displayed.
                  </li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/bulkAddPrompts.png"
                alt="Add User Prompt"
                title="Add User Prompt"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Step 4: View/Use the Prompts</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Return to the Prompt Catalog.</li>
                  <li>The prompts appears in the order they were created.</li>
                  <li>Optionally reorder or filter the prompts.</li>
                  <li>Send the prompts to the playground for testing.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/newPrompts.png"
                alt="New Prompts"
                title="New Prompts"
              />
              <br />
              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully bulk created new prompts in Dataster. This
                is the recommended way to create a full use case that you can
                eventually test against a broad variety of language models and
                Retrieval-Augmented Generation (RAG) systems.
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
