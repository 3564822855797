import ImageGallery from "../../imageGallery/imageGallery";
import ShareButtons from "../../ui/shareButtons";
import styles from "./solutionItem.module.css";

export default function SolutionItem({
  imgUrl,
  imgTitle,
  title,
  children,
  urlSuffix,
}) {
  return (
    <div className={styles.container}>
      <ImageGallery image={imgUrl} alt={imgTitle} title={imgTitle} />
      <div className={styles.desc}>
        <h2>{title}</h2>
        <div>
          <p>{children}</p>
        </div>
        <div className={styles.share}>
          <ShareButtons url={`www.dataster.com/${urlSuffix}/`} title={title} />
        </div>
      </div>
    </div>
  );
}
