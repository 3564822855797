import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ImageGallery from "../../imageGallery/imageGallery";
import ArrowLeftSmallIcon from "../../ui/hamburger/arrowLeftSmall";
import DatasterHelps from "../../components/datasterHelps";

export default function ByoAOAI(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>
          Dataster Documentation - Bring your own Azure OpenAI model
        </title>
        <meta
          name="description"
          content="Guide to bringing your own Azure OpenAI model into Dataster"
        />
        <link rel="canonical" href="https://www.dataster.com/docs/byo-aoai/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div className="page-container__about__desc">
            <div className="page-container__doc_back">
              <ArrowLeftSmallIcon />
              <NavLink
                to="/docs/"
                className={({ isActive }) => (isActive ? "active-link" : "")}
              >
                Back to documentation
              </NavLink>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ fontFamily: "Roboto" }}>
                Bring your own Azure OpenAI model
              </h2>
            </div>
            <div>
              <p>
                Dataster supports many Azure OpenAI models both for completions
                and embeddings. The full list of models can be found on our
                pricing page{" "}
                <NavLink
                  to="/pricing"
                  className={({ isActive }) => (isActive ? "active-link" : "")}
                  style={{ color: "black", textDecoration: "underline" }}
                >
                  here
                </NavLink>
                . Dataster provides off-the-shelf access to each supported
                model, which is shared across several users. However, users can
                choose to bring their own Azure OpenAI model into Dataster for
                isolated and private usage, eliminating potential competition
                for resources with other users. The process of bringing your own
                Azure OpenAI model into Dataster is straightforward.
              </p>

              <h3 style={{ margin: "1rem 0" }}>Prerequisites</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>A Dataster account.</li>
                  <li>
                    An existing and publicly accessible deployment in an Azure
                    Subscription.
                  </li>
                  <li>An API key for the Azure resource.</li>
                </ol>
              </p>
              <h3 style={{ margin: "1rem 0" }}>
                Step 1: Navigate to the LLM Catalog
              </h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>
                    Navigate to the LLM catalog by clicking "LLM" in the left
                    navigation pane.
                  </li>
                </ol>
              </p>

              <h3 style={{ margin: "1rem 0" }}>Step 2: Add an LLM</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Click BYO LLM.</li>
                  <li>Fill in a unique name for the LLM across the account.</li>
                  <li>Set the provider to Azure OpenAI.</li>
                  <li>
                    Use the drop-down menu to indicate which model is deployed.
                  </li>
                  <li>
                    Fill in the names of both the Azure OpenAI resource and the
                    name of the deployment as their appear in the Azure
                    subscription.
                  </li>
                  <li>Fill in the API key.</li>
                  <li>Click Add.</li>
                </ol>
                <br />
              </p>
              <br />
              <ImageGallery
                image="/documentation/addAOAILLM.png"
                alt="Add an AOAI Model"
                title="Add an AOAI Model"
              />
              <br />

              <h3 style={{ margin: "1rem 0" }}>Step 3: View the Model</h3>
              <p>
                <ol style={{ padding: "0 1rem" }}>
                  <li>Return to the LLM Catalog.</li>
                  <li>The model appears in the order it was created.</li>
                  <li>Optionally filter the models.</li>
                </ol>
                <br />
              </p>

              <h3 style={{ margin: "1rem 0" }}>Conclusion</h3>
              <p>
                You have successfully brought your own Azure OpenAI model in
                Dataster. This model is now available for use case evaluation or
                can be combined with a vector store for Retrieval-Augmented
                Generation (RAG).
              </p>
              <br />
              <p>
                If you encounter any issues or need further assistance, please
                contact our support team at support@dataster.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
