import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "../ui/hamburger/chevronRight";
import DatasterHelps from "../components/datasterHelps";

export default function Docs(props) {
  const { onClick } = props;
  return (
    <>
      <Helmet>
        <title>Dataster Documentation</title>
        <meta
          name="description"
          content="Explore Dataster's comprehensive documentation for GenAIOps and LLMOps. Find detailed how-tos, tutorials and explanations to effectively use our application"
        />
        <link rel="canonical" href="https://www.dataster.com/docs/" />
      </Helmet>
      <div className="page-container" onClick={onClick}>
        <h1>Dataster - Documentation</h1>
        <DatasterHelps />
        <div className="page-container__about">
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div className="page-container__about__desc">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ fontFamily: "Roboto" }}>Prompts</h2>
              </div>
              <div>
                <div className="page-container__doc_links">
                  <ul>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/add-a-prompt/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Add a Prompt
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/bulk-add-prompts/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Bulk add Prompts
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/bulk-delete-prompts/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Bulk delete Prompts
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-container__about__desc">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ fontFamily: "Roboto" }}>
                  Large Language Models (LLMs)
                </h2>
              </div>
              <div>
                <div className="page-container__doc_links">
                  <ul>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/llms-overview/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Overview
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/byo-aoai/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Bring Your Own (BYO) Azure OpenAI model
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/byo-bedrock/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Bring Your Own (BYO) Amazon Bedrock model
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/byo-oai/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Bring Your Own (BYO) OpenAI model
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-container__about__desc">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ fontFamily: "Roboto" }}>Vector Stores</h2>
              </div>
              <div>
                <div className="page-container__doc_links">
                  <ul>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/add-an-amazon-opensearch-serverless-vector-store/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Add an Amazon OpenSearch Serverless Vector Store
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/add-an-azure-aisearch-vector-store/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Add an Azure AI Search Vector Store
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-container__about__desc">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ fontFamily: "Roboto" }}>
                  Retrieval Augmented Generation (RAG)
                </h2>
              </div>
              <div>
                <div className="page-container__doc_links">
                  <ul>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/rag-overview/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Overview
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/add-a-rag/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Add a RAG
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="page-container__about__desc">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ fontFamily: "Roboto" }}>Latency Tests</h2>
              </div>
              <div>
                <div className="page-container__doc_links">
                  <ul>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/latency-overview/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Overview
                        </NavLink>
                      </div>
                    </li>
                    <li>
                      <div>
                        <div>
                          <ChevronRightIcon />
                        </div>
                        <NavLink
                          to="/docs/run-a-latency-test/"
                          className={({ isActive }) =>
                            isActive ? "active-link" : ""
                          }
                        >
                          Run a Latency Test
                        </NavLink>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
